@import '~crazy-ui-next/src/scss/common/typography';
@import '~crazy-ui-next/src/scss/common/colors';
@import '~crazy-ui-next/src/scss/common/shadows';

.uploadCell {
  position: relative;

  input {
    display: none;
  }

  &.error {
    border-color: $magenta-500;
  }

  span {
    white-space: pre-wrap;
  }

  .controls {
    display: none;
    justify-content: center;
    align-items: center;
    background: $cyan-100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover .controls {
    display: flex;
  }

  &[data-highlight='true'] {
    color: $gray-500;
  }

  .cellLoader {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: $primary;
    }
  }
}

.modal {
  color: $text-color;

  ul {
    li {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        white-space: nowrap;

        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 600px;
      }
    }
  }

  p {
    span {
      color: $primary;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    span {
      color: $primary;
    }

    p {
      @include text-h3-medium;
      vertical-align: middle;
      color: $gray-800;
    }
  }

  .controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    gap: 16px;
  }
}
